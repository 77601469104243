define(['$window'], ($window) => {

  const responsiveProductTabs = ()  => {
    const component = {};
    let _config = {};
    let _elements = {};
    let classPrefix;
    const tabAriaHidden = 'aria-hidden';
    const tabAriaSelected = 'aria-selected';
    const tabAriaLabeledBy = 'aria-labelledby';
    const tabTabIndex = 'tabindex';

    const keys = {
      left: 'ArrowLeft',
      ieLeft: 'Left',
      right: 'ArrowRight',
      ieRight: 'Right',
      end: 'End',
      home: 'Home',
    };

    const _init = (element) => {
      classPrefix = element.getAttribute('data-tab-prefix');
      component.element = element;

      _config = {
        tabButtonClass: `.${classPrefix}_tabHeader`,
        tabButtonId: `#${classPrefix}_tab`,
        tabActiveClass: `${classPrefix}_tabHeader-active`,
        tabContentActiveClass: `${classPrefix}_tab-active`,
        tabChangedAttribute: 'data-tab-changed',
      };

      _elements = {
        tabButtons: component.element.querySelectorAll('[role="tab"]'),
        tabContents: component.element.querySelectorAll('[role="tabpanel"]'),
        tabActiveMarker: component.element.querySelector(`.${classPrefix}_tabActiveMarker`),
        activeTab: null,
        ratingStarsContainer: component.element.querySelectorAll('.productBlock_ratingStarsContainer'),
      }

      component.config = _config;
      component.elements = _elements;
      component.setupTabButtons();

      $window.addEventListener('optimizedResize', component.updateTabMarker, false);
      component.throttle('resize', 'optimizedResize');
      component.reviewStarsIterator();
    };

    const _keyEventListener = (event, i) => {
      let nextTab;
      let el;
      const key = event.key;
      const totalTabs = Array.from(component.elements.tabButtons).length;

      switch(key) {
        case keys.ieLeft:
        case keys.left:
          event.preventDefault();
          nextTab = i;
          el = component.element.querySelector(component.config.tabButtonId + `_${nextTab}`);
          (i >= 1) && component.showTab(el);
          break;

        case keys.ieRight:
        case keys.right:
          event.preventDefault();
          nextTab = i + 1;
          el = component.element.querySelector(component.config.tabButtonId + `_${nextTab + 1}`);
          (nextTab < totalTabs) && component.showTab(el);
          break;

        case keys.home:
          event.preventDefault();
          el = component.element.querySelector(component.config.tabButtonId + `_1`);
          component.showTab(el);
          break;

        case keys.end:
          event.preventDefault();
          el = component.element.querySelector(component.config.tabButtonId + `_${totalTabs}`);
          component.showTab(el);
          break;
      }
    };

    const _setupTabButtons = () => {
      const tabArray = Array.from(component.elements.tabButtons);
      component.elements.activeTab = tabArray[0];
      component.updateTabMarker();
      tabArray.map((el, i) => {
        el.addEventListener('click', (event) => { event.preventDefault(); component.showTab(el) } );
        el.addEventListener('keydown', (event) => { component.keyEventListener(event, i) });
      });

    };

    const _showTab = (el) => {
      component.element.setAttribute(component.config.tabChangedAttribute, 'true');
      component.removeActiveClasses();

      let associatedContent = component.element.querySelector(`[${component.tabAriaLabeledBy}="${el.id}"]`);
      associatedContent.classList.add(component.config.tabContentActiveClass);
      associatedContent.setAttribute(component.tabAriaHidden, 'false');

      el.classList.add(component.config.tabActiveClass);
      el.setAttribute(component.tabAriaSelected, 'true');
      el.removeAttribute(component.tabTabIndex);
      el.focus();
      component.elements.activeTab = el;
      component.updateTabMarker();
    };

    const _updateTabMarker = () => {
      if(component.elements.activeTab) {
        component.elements.tabActiveMarker.style.left = `${component.elements.activeTab.offsetLeft}px`;
        component.elements.tabActiveMarker.style.width = `${component.elements.activeTab.clientWidth}px`;
      }
    };

    const _removeActiveClasses = () => {
      Array.from(component.elements.tabContents).map(el => {
        el.classList.remove(component.config.tabContentActiveClass);
        el.setAttribute(component.tabAriaHidden, 'true');
      });

      Array.from(component.elements.tabButtons).map(el => {
        el.classList.remove(component.config.tabActiveClass);
        el.setAttribute(component.tabAriaSelected, 'false');
        el.setAttribute(component.tabTabIndex, '-1');
      });
    };

    const _throttle = function(type, name, comp) {
      comp = comp || $window;
      var running = false;
      var func = function() {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function() {
          comp.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      comp.addEventListener(type, func);
    };

    const _reviewStarsIterator = () => {

      if (component.elements.ratingStarsContainer.length < 1) return false;

      component.elements.ratingStarsContainer.forEach( (item, index) => {
        component.reviewStarsUpdateValues(item, index.toString());
      })
    };

    const _reviewStarsUpdateValues = (item,iterateString) => {
      try {
        let gradient = item.querySelector('linearGradient');
        let path = item.querySelector('path');
        let gradientURL = gradient.getAttribute('id') + iterateString;

        gradient.setAttribute('id',gradientURL);
        path.setAttribute('fill',`url(#${gradientURL})`);
      } catch (e) {
        return;
      }

    };

    component.config = _config;
    component.init = _init;
    component.showTab = _showTab;
    component.removeActiveClasses = _removeActiveClasses;
    component.setupTabButtons = _setupTabButtons;
    component.updateTabMarker = _updateTabMarker;
    component.keyEventListener = _keyEventListener;
    component.tabAriaLabeledBy = tabAriaLabeledBy;
    component.tabAriaHidden = tabAriaHidden;
    component.tabAriaSelected = tabAriaSelected;
    component.tabTabIndex = tabTabIndex;
    component.throttle = _throttle;
    component.reviewStarsIterator = _reviewStarsIterator;
    component.reviewStarsUpdateValues =_reviewStarsUpdateValues;

    return component;
  };

  return responsiveProductTabs;
});
